// extracted by mini-css-extract-plugin
export var ProjectContentWrap = "Project-module--ProjectContentWrap--OhwG3";
export var ProjectHero = "Project-module--ProjectHero--CkIb8";
export var ProjectHeroImage = "Project-module--ProjectHeroImage--B9n1e";
export var ProjectMasonryImage = "Project-module--ProjectMasonryImage--QDt82";
export var ProjectMasonryLayout = "Project-module--ProjectMasonryLayout--kzX9k";
export var ProjectModalImage = "Project-module--ProjectModalImage--Z4i0i";
export var ProjectSection = "Project-module--ProjectSection--2pM7l";
export var ProjectSpecsBreak = "Project-module--ProjectSpecsBreak--TpUJi";
export var ProjectSpecsWrap = "Project-module--ProjectSpecsWrap--A3Ms1";
export var ProjectTitle = "Project-module--ProjectTitle--V1fH2";
export var ProjectWrap = "Project-module--ProjectWrap--11ucz";